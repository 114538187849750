import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Popover
} from "@material-ui/core";
import { message,downloadIcon, optionIcon, rejected, pending, deleteIcon, markAsRead } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ height: "100%", display: "flex", overflow: "hidden" }} >
        <Box>
          <NavigationMenu id="" navigation={this.props.navigation}></NavigationMenu>
        </Box>
        <Box style={{ ...webStyle.container, overflowY: "scroll", scrollbarWidth: "none", }}>
          <Box style={webStyle.notificationHeader}>
            <Typography style={webStyle.headerText}>Notification</Typography>
          </Box>
          {
            this.state.data.map((item, itemIndex) => (
              <Box key={itemIndex} style={webStyle.notificationTab}>
                <Box
                  style={{
                    ...webStyle.notificationContainer,
                    backgroundColor: item.attributes.is_read ? "#FFFFFF" : "#EFF6FF",
                  }}
                >
                  <img
                    src={item.attributes.status == "pending" ? pending : item.attributes.status == "rejected" ? rejected : message}
                    style={{ height: "24px", width: "24px" }}
                    alt="message-icon"
                  />
                  <Typography style={webStyle.notificationContent} >
                    {item.attributes.contents}
                  </Typography>
                  <Typography data-test-id="downloadBtn" style={webStyle.downloadBtn} onClick={() => this.downloadFile(`${item.attributes.app_url}`)} >
                    <img src={downloadIcon} alt="download-icon" />
                    Download
                  </Typography>
                  <img data-test-id="moreBtn" src={optionIcon} alt="options-icon" onClick={(event) => this.handleOptionOpen(event, item.id)} />
                </Box>
                <Popover
                  id="simple-popover"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleOptionClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    style: webStyle.optionMenu,
                  }}
                >
                  <Box style={webStyle.optionMarkAsRead}>
                    <img src={markAsRead} alt="markAsReadIcon" style={{ height: "24px", width: "24px" }} />
                    <Typography data-test-id="markAsRead" onClick={() => this.handleMarkAsRead()} style={{ cursor: "pointer" }} >Mark as read</Typography>
                  </Box>
                  <Box style={webStyle.optionDelete}>
                    <img src={deleteIcon} alt="deleteIcon" style={{ height: "24px", width: "24px" }} />
                    <Typography data-test-id="delete" style={{ cursor: "pointer" }} onClick={() => this.handleDeleteNotification()} >Delete</Typography>
                  </Box>
                </Popover>
              </Box>
            ))
          }
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    width: "auto",
    margin: "0 auto",
    padding: "25px",
    backgroundColor: "#F8FAFC",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
  },
  notificationHeader: {
    paddingBottom: "20px",
  },
  headerText: {
    fontWeight: "400",
    fontSize: "30px",
    color: "#334155",
  },
  notificationTab: {
    width: "100%",
  },
  notificationContainer: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    padding: "18px 40px",
    gap: "24px",
    borderBottom: "1px solid #CBD5E1",
    backgroundColor: "#EFF6FF",
  },
  notificationContent: {
    flex: 1,
    fontSize: "14px",
    fontWeight: "400",
    color: "#0F172A",
  },
  downloadBtn: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B4FE4",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor:"pointer"
  },
  optionMenu:{
    height:"96px",
    width: "178px",
    border: "1px solid #F5F5F5",
    boxShadow: "none",
    borderRadius:"8px",
    background: "#FFFFFF"
  },
  optionMarkAsRead:{
    display:"flex",
    height:"48px",
    alignItems:"center",
    gap:"8px",
    paddingLeft:"16px",
    fontWeight:"400",
    fontSize:"16px",
    color:"#F8FAFC",
    backgroundColor:"#1B4FE4"
  },
  optionDelete:{
    display:"flex",
    height:"48px",
    alignItems:"center",
    gap:"8px",
    paddingLeft:"16px",
    fontWeight:"400",
    fontSize:"16px"
  }
};
// Customizable Area End
