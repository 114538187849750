// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import { Typography } from "@material-ui/core";
import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
import {CountryData} from "react-phone-input-2";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
}
interface S {
  open: boolean;
  form: {
      name: string,
      label: string,
      placeholder: string,
      isMandatory: boolean,
      type: string,
    }[],
  withdrawalDropDown: boolean;
  countryOption: boolean;
  withdrawalDropDown2: boolean;
  withdrawalOptions: {label: string, value: string}[];
  backPopup: boolean,
  sendRequest: boolean,
  formattedDate: string,
  inputRef: any,
  country: string
  sendAfterDisabled: boolean,
  }
interface SS {}

interface TouchedType {
  withdraw: string,
  withdrawConfirmation: string,
  bankName: string,
  swiftCode: string,
  accountName: string,
  accountNum: string,
  bankAddress: string
}
// Customizable Area End

export default class WithdrawFormsControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.state = {
      open: false,
      withdrawalDropDown: false,
      inputRef: React.createRef(),
      country:"United State",
      formattedDate: `${this.formatDate(new Date())}`,
      withdrawalDropDown2: false,
      countryOption: false,
      backPopup: false,
      sendAfterDisabled:false,
      sendRequest: false,
      withdrawalOptions: 
      [
        { label: "USD", value: "United States Dollar" },
        { label: "EUR", value: "Euro" },
        { label: "GBP", value: "British Pound" },
        { label: "JPY", value: "Japanese Yen" },
        { label: "AUD", value: "Australian Dollar" },
        { label: "CAD", value: "Canadian Dollar" },
        { label: "CHF", value: "Swiss Franc" },
        { label: "CNY", value: "Chinese Yuan" },
        { label: "SEK", value: "Swedish Krona" },
        { label: "NZD", value: "New Zealand Dollar" },
        { label: "MXN", value: "Mexican Peso" },
        { label: "SGD", value: "Singapore Dollar" },
        { label: "HKD", value: "Hong Kong Dollar" },
        { label: "NOK", value: "Norwegian Krone" },
        { label: "OMR", value: "Omani Rial" },
        { label: "KRW", value: "South Korean Won" },
        { label: "TRY", value: "Turkish Lira" },
        { label: "RUB", value: "Russian Ruble" },
        { label: "INR", value: "Indian Rupee" },
        { label: "BRL", value: "Brazilian Real" },
        { label: "ZAR", value: "South African Rand" },
        { label: "PHP", value: "Philippine Peso" },
        { label: "CZK", value: "Czech Koruna" },
        { label: "IDR", value: "Indonesian Rupiah" },
        { label: "MYR", value: "Malaysian Ringgit" },
        { label: "HUF", value: "Hungarian Forint" },
        { label: "ISK", value: "Icelandic Krona" },
        { label: "HRK", value: "Croatian Kuna" },
        { label: "BGN", value: "Bulgarian Lev" },
        { label: "RON", value: "Romanian Leu" },
        { label: "DKK", value: "Danish Krone" },
        { label: "THB", value: "Thai Baht" },
        { label: "PLN", value: "Polish Zloty" }
      ],
      form: [
        {
          name: "accountNum",
          label: "Bank Account Number",
          placeholder: "Enter your bank account number",
          isMandatory: true,
          type: "text",
        },
        {
          name: "bankAddress",
          label: "Bank Address",
          placeholder: "Enter your bank address",
          isMandatory: true,
          type: "text",
        },
      ],
    };
    // Customizable Area End
  }

  // Customizable Area Start
  withdrawFormId:string = "";

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apierror = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.withdrawFormId) {
      if(apierror) {
        if(Object.keys(apierror.errors[0]).includes("token")) {
          toast.error("Token is expired")
          const message: Message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(message);
        }else
        // this.setState({sendAfterDisabled: false})
        Object.values(apierror.errors[0]).forEach((item: any) => toast.error(item));
      } else if (responseJson?.data?.data?.attributes?.status === 'rejected'){
        // this.setState({ sendAfterDisabled: false})
        responseJson.messages.map((item: {message: string}) => toast.error(item));
      } else {
        this.setState({sendRequest: true})
        responseJson.messages.map((item:{message: string}) => toast.success(item))
      }
      // Disable submissions for the next 5 seconds
      this.setState({ sendAfterDisabled: true })
      setTimeout(() => {
        this.setState({ sendAfterDisabled: false })
      }, 5000);
    }
    
  }

  WithdrawFormPost = async (values: {
      withdraw: string,
      withdrawConfirmation: string,
      bankName: string,
      swiftCode: string,
      accountName: string,
      accountNum: string,
      bankAddress: string,
      phonecode: string,
      country: string
    }) => {
      if(this.state.sendAfterDisabled) return;
      this.setState({sendAfterDisabled: true})
      const requestId = await getStorageData("withdrawRequestId")
    const formData = new FormData();
    formData.append("data[attributes][request_id]", requestId);
    formData.append("data[attributes][amount]", values.withdraw);
    formData.append("data[attributes][amount_confirmation]", values.withdrawConfirmation);
    formData.append("data[attributes][bank_name]", values.bankName);
    formData.append("data[attributes][swift_code]", values.swiftCode);
    formData.append("data[attributes][name_in_bank_account]", values.accountName);
    formData.append("data[attributes][bank_account_number]", values.accountNum);
    formData.append("data[attributes][bank_address]", values.bankAddress);
    formData.append("data[attributes][currency]", values.phonecode);
    formData.append("data[attributes][preferred_date]", this.state.formattedDate);
    formData.append("data[attributes][country]", this.state.country);
    const headers = {
      contentType: configJSON.contentTypeFormData,
      token: localStorage.getItem('token')
    };
    const withdrawFormData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    withdrawFormData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.withdrawFormId = withdrawFormData.messageId;
    withdrawFormData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.withdrawFormEndPoint
    );
    withdrawFormData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    withdrawFormData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(withdrawFormData.id, withdrawFormData);
  }

  withdrawSchema = () => {
    return Yup.object().shape({
      withdraw: Yup.number()
      .required('Please enter your withdraw value')
      .min(1, 'Withdraw value must be at least 1'),
    withdrawConfirmation: Yup.number()
      .oneOf([Yup.ref('withdraw'), null], 'Withdraw values must match')
      .required('Please confirm your withdraw value'),
    bankName: Yup.string().required('Please enter your bank name'),
    swiftCode: Yup.string().required('Please enter your swift code'),
    accountName: Yup.string().required('Please enter your name in bank account'),
    accountNum: Yup.string().required('Please enter your account number'),
    bankAddress: Yup.string().required('Please enter your bank address')
    });
  };

  getErrorMessage = (touched: any, errors: any, value: any) => {
    const style = {marginTop: "2px",
    fontSize: "12px",
    color: "#DC2626",
    fontFamily: "DIN Next LT Arabic Regular"}
    return (
      this.handleCondition(touched[value], errors[value], "") ?
      <Typography style={style}>
        {touched[value as keyof TouchedType] && errors[value as keyof TouchedType]}
      </Typography> : ""
    );
  };

  handleOpenPopup = () => {
    this.setState({ backPopup: !this.state.backPopup });
  };

  handleBackClick = () => {
    this.props.navigation.navigate("WithdrawRequest");
  };

  handleSendPopup = () => {
    this.setState({ sendRequest: !this.state.sendRequest });
  };

  withdrawDropdown2 = () => {
    this.setState({withdrawalDropDown2: !this.state.withdrawalDropDown2})
  }

  withdrawDropdown = () => {
    this.setState({withdrawalDropDown: !this.state.withdrawalDropDown})
  }

  handleBankName = () => {
    this.setState({countryOption: !this.state.countryOption})
  }

  handleDateChange = (event: {target: {value: string}}) => {
    const dateValue = event.target.value;
    if (dateValue) {
      const date = new Date(dateValue);
      const formatted = this.formatDate(date);
      this.setState({formattedDate: formatted});
    } 
    this.setState({sendAfterDisabled: false})
  };

  formatDate = (date: Date) => {
    const day = ("0" + date.getDate()).slice(-2);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  handleCalendarOpen = () => {
    if (this.state.inputRef.current) {
      return this.state.inputRef.current.showPicker();
    }
  };

  handlePhoneChange = (value:string, countryData: CountryData) => {
    this.setState({ country: countryData.name, sendAfterDisabled: false});
  };

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };

  // Customizable Area End
}
