// Customizable Area Start
import React from "react";
import { Box, Typography, createTheme } from "@material-ui/core";
import {
    styled
} from "@material-ui/core/styles";
import UserProfileBasicBlockController, {
    IFAQ,
    Props,
} from "./UserProfileBasicController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { downIcon } from "../../landingpage/src/assets";
import { imgArrow, imgArrowSmall } from "../../customform/src/assets";
import Contactus from "../../contactus/src/Contactus";
const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });
// Customizable Area End

export default class HelpCentre extends UserProfileBasicBlockController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    MainWrapper = styled(Box)(({ theme }) => ({
        zIndex: -1,
        position: "absolute",
        paddingTop: '4rem',
        width: "100%",
        height:"100vh",
        overflowY:"scroll",
        [theme.breakpoints.up(900)]: {
            zIndex: 0,
            position: "inherit"
        },
        "& .container": {
            [theme.breakpoints.down(900)]: {
                width: "calc(100vw - 69px)",
                padding: "5px 10px 10px 3rem",
                position: "absolute",
                top: "0",
            },
            [theme.breakpoints.down(750)]: {
                padding: "5px 10px 10px 10px",
            },
            [theme.breakpoints.down(480)]: {
                marginTop: '4vh'
            },
            minWidth: "100%",
            width: "calc(100vw - 493px)",
            padding: "0 10px 10px 2rem",
        },

        "& .questionCon": {
            [theme.breakpoints.down(480)]: {
                paddingTop: "2vw",
                paddingBottom: "1vw",
            },
            paddingTop: "4vw",
            paddingBottom: "5vw",
        },
        "& .questionHeadingContainer": {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "flex-start",
            justifyContent: "center",
            [theme.breakpoints.down(480)]: {
                padding: "1vw",
            },
        },
        "& .freqQuestion": {
            color: "#292524",
            fontWeight: 700,
            fontFamily: "DIN Next LT Arabic Bold",
            [theme.breakpoints.down(480)]: {
                fontSize: "18px",
            },
            fontSize: "36px",
        },
        "& .freqSubHeading": {
            color: "#475569",
            textAlign: "center" as "center",
            fontWeight: 400,
            fontFamily: "DIN Next LT Arabic Regular",
            [theme.breakpoints.down(480)]: {
                fontSize: "16px",
                textAlign: 'start'
            },
            fontSize: "16px",
        },
        "& .contentBox": {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column" as "column",
            [theme.breakpoints.down(480)]: {
                gap: "2.5vw",
            },
            padding: "2vw 0",
            gap: "1.2vw",
        },
        "& .contentItem": {
            [theme.breakpoints.down(480)]: {
                width: "95vw",
            },
            width: "60vw",
        },
        "& .contentHeading": {
            display: "flex",
            alignItems: "center",
            gap: "1vw",
            padding: "1vw",
            justifyContent: "space-between",
            borderRadius: "0.8vw",
            background: "#FFF",
            boxShadow:
                " 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
        },
        "& .contentQuestion": {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "flex-start",
            fontFamily: "DIN Next LT Arabic Regular",
            [theme.breakpoints.down(480)]: {
                fontSize: "16px",
                gap: "0.5vw",
            },
            fontSize: "18px",
            gap: "0.1vw",
        },
        "& .downIcon": {
            [theme.breakpoints.down(480)]: {
                width: "25px",
            },
            width: "25px",
        },
        "& .rotatedIcon": {
            transform: `rotate(180deg)`,
            transition: 'transform 0.3s ease'
        }, 
        "& .optionBox": {
            display: "flex",
            flexDirection: "column", 
            alignItems: "flex-start", 
            padding: "0 1.1vw",
            border: "0.01vw solid #F1F5F9",
            background: "#F1F5F9",
            boxShadow: "0px 2px 8px 0px #00000014",
            fontFamily: "DIN Next LT Arabic Regular",
            [theme.breakpoints.down(480)]: {
                fontSize: "16px",
                color: "#1E293B",
                borderRadius: "1vw",
            },
            fontSize: "18px",
            color: "#1E293B",
            borderRadius: "0.5vw",
            whiteSpace: "normal",  
            wordWrap: "break-word",  
            overflow: "scroll",  // Prevents content from overflowing
            textOverflow: "ellipsis", 
        },
        "& .contactusTextBox": {
            marginTop: '1.2vw',
            [theme.breakpoints.down(480)]: {
                marginTop: '2.5vw',
            },
        },
        "& .contactusFontStyle": {
            color: "#292524",
            fontWeight: 500,
            fontFamily: "DIN Next LT Arabic Regular",
            [theme.breakpoints.down(480)]: {
                fontSize: "18px",
            },
            fontSize: "18px",
        },
        "& .contactuslink": {
            fontFamily: "DIN Next LT Arabic Bold",
            color: "#1B4FE4",
            fontWeight: 700,
            paddingLeft:"2.5rem"
        },
        "& .arrowButton": {
            width:'32px',
            height:'32px',
            marginLeft:"-20px",
            cursor: 'pointer',
            display: "flex",
            '&:hover': {
              transition: "0.7s"
            },
            [theme.breakpoints.down(480)]: {
                marginLeft:"0",
                marginTop: '4vh'
            },
        },
        "& .arrowAndHeadingBox": {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            [theme.breakpoints.down(480)]: {
               flexDirection: 'row'
            },
        }


    }));
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {investorDescription, freqAskQuestion, activeAnswerIndex} = this.state;
        return (
            <div style={{ display: "flex"}}>
                <Box>
                    <NavigationMenu id="" navigation={this.props.navigation}></NavigationMenu>
                </Box>
                <this.MainWrapper>
                    {this.state.openContactUs && <Contactus data-testid="contact-us-form" onClose={this.handleCloseContactUs} navigation={this.props.navigation} id={this.props.id}/>}
                    <Box className="container">
                        <Box className="questionCon">
                            <Box className="arrowAndHeadingBox">
                                <Box data-test-id="navigateToSignUpPage_term" onClick={this.navigateToDashBoardPage} sx={{
                                    [theme.breakpoints.up("xs")]: {
                                        backgroundImage: `url(${imgArrow})`,
                                        backgroundRepeat: 'no-repeat'
                                    },
                                    [theme.breakpoints.down(985)]: {
                                        backgroundImage: `url(${imgArrowSmall})`,
                                        backgroundRepeat: 'no-repeat'
                                    }
                                }}
                                    className="arrowButton"
                                >
                                </Box>
                                <Box
                                    className="questionHeadingContainer"
                                >
                                    <Typography
                                        className="freqQuestion"
                                    >
                                        Frequently Asked Questions
                                    </Typography>
                                    <div
                                        className="freqSubHeading"
                                        dangerouslySetInnerHTML={{
                                            __html: investorDescription,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box className="contentBox">
                                {freqAskQuestion.map((obj: IFAQ, index: any) => (
                                    <Box key={index} className="contentItem">
                                        <Box className="contentHeading">
                                            <Typography className="contentQuestion">
                                                {obj.question}
                                            </Typography>
                                            <img
                                                className={`downIcon ${this.state.activeAnswerIndex === index ? 'rotatedIcon' : ''}`}
                                                data-test-id="downIcon"
                                                src={downIcon}
                                                onClick={() => this.setActiveAnswerIndex(index)}
                                            />
                                        </Box>
                                        {this.handleCondition(
                                            this.state.activeAnswerIndex === index,
                                            <Box
                                                className="optionBox"
                                                dangerouslySetInnerHTML={{ __html: obj.description }}
                                            />,
                                            ""
                                        )}
                                    </Box>
                                ))}

                            </Box>
                            <Box
                                className="contactusTextBox"
                            >
                                <Typography
                                    className="contactusFontStyle"
                                >
                                    Do you have any other queries?<span onClick={() => this.handleOpenContactUs()} className="contactuslink">Contact Us</span>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </this.MainWrapper>
            </div>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End
