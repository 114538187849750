// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import * as Yup from "yup";
import { Typography } from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";

import { approvedIcon, contactReviewInitIcon, contactReviewLaterIcon, contactSubmitInitIcon, formFillInitIcon, formFillLaterIcon, formReviewInitIcon, formReviewLaterIcon, rejectedIcon } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import ApiRequest from "../../../components/src/ApiRequest.web"
import { formatDateToMonDayYear } from "../../../components/src/utils.web";

export const configJSON = require("./config");

export type TStatus = "pending" | "rejected" | "approved" | "submitted" | "completed" | "fill form" | "not started";
export interface IDepostiStages {
  id: string;
  IconInit: any;
  IconPending?: any;
  IconSuccess: any;
  IconReject?: any;
  title: string;
  description: string;
  status: TStatus;
  successStatus: TStatus;
}

const initialDepositStages: IDepostiStages[] = [
  {
    id: "1",
    IconInit: contactSubmitInitIcon,
    IconSuccess: approvedIcon,
    title: 'Step 1',
    description: 'Contract Submit',
    status: "fill form",
    successStatus: "completed",
  },
  {
    id: "2",
    IconInit: contactReviewInitIcon,
    IconPending: contactReviewLaterIcon,
    IconSuccess: approvedIcon,
    IconReject: rejectedIcon,
    title: 'Step 2',
    description: 'Contract Review',
    status: "not started",
    successStatus: "approved",
  },
  {
    id: "3",
    IconInit: formFillInitIcon,
    IconPending: formFillLaterIcon,
    IconSuccess: approvedIcon,
    IconReject: rejectedIcon,
    title: 'Step 3',
    description: 'Form Filling',
    status: "fill form",
    successStatus: "submitted",
  },
  {
    id: "4",
    IconInit: formReviewInitIcon,
    IconPending: formReviewLaterIcon,
    IconSuccess: approvedIcon,
    IconReject: rejectedIcon,
    title: 'Step 4',
    description: 'Form Review',
    status: "not started",
    successStatus: "approved",
  },
]
export interface IPaginationData {
  currentPage: number;
  nextPage: number;
  prevPage: number;
  totalPages: number;
  currentCount: number;
  totalCount: number;
}
interface IDepositRequestList {
  date: string;
  transactionId: string;
  amount: string;
  status: string;
}
export interface ITransactionOptions {
  label: string; 
  value: string
}

export interface Props {
  navigation: any;
  id: string;
}
export interface FormIn {
  name: string;
  label: string;
  placeholder: string;
  isMandatory: boolean;
  type: string;
}

interface TouchedType {
  email: string;
  account: string;
  remarks: string;
  transactionId: string;
  acoount_name: string;
  transaction_purpose: string;
  file: string;
}
interface S {
  //   form: FormIn[];
  form: {
    name: string;
    label: string;
    placeholder: string;
    isMandatory: boolean;
    type: string;
  }[];
  backPopup: boolean;
  sendRequest: boolean;
  files: boolean;
  sendButton: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  transactionOptions: ITransactionOptions[];
  formattedDate: string;
  depositStages: IDepostiStages[];
  showButton: boolean;
  depositRequestList: IDepositRequestList[],
  paginationData: IPaginationData,
  signed_contract_id: number;
  contractUserEmail: string;
  contractAmouont: string;
  secondPartyName: string;
  disabledAfterSend:boolean;
  depositRequestId: string;
  fileErrorMsg: string;
}
interface SS {}
// Customizable Area End

export default class MultipageFormsControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.state = {
      inputRef: React.createRef(),
      backPopup: false,
      formattedDate: `${this.formatDate(new Date)}`,
      sendRequest: false,
      files: false,
      sendButton: false,
      disabledAfterSend: false,
      transactionOptions: [
        { label: "Investment", value: "investment" },
        { label: "Business", value: "business" },
      ],
      form: [
        {
          name: "email",
          label: "Email",
          placeholder: "Enter your email",
          isMandatory: true,
          type: "text",
        },
        {
          name: "account",
          label: "Transaction value",
          placeholder: "Amount",
          isMandatory: true,
          type: "text",
        },
        {
          name: "remarks",
          label: "Remarks",
          placeholder: "Enter your remarks",
          isMandatory: false,
          type: "text",
        },
        {
          name: "transactionId",
          label: "Transaction ID",
          placeholder: "Transaction ID (Reference)",
          isMandatory: true,
          type: "text",
        },
        {
          name: "acoount_name",
          label: "Name in Bank Account",
          placeholder: "Enter your name in bank account",
          isMandatory: true,
          type: "text",
        },
      ],
      depositStages: [],
      showButton: true,
      depositRequestList: [],
      paginationData: {
        currentPage: 1,
        nextPage: 0,
        prevPage: 0,
        totalPages: 0,
        currentCount: 0,
        totalCount: 0
      },
      signed_contract_id: -1,
      contractUserEmail: '',
      contractAmouont: '',
      secondPartyName: '',
      depositRequestId: '',
      fileErrorMsg: ''
    };
    // Customizable Area End
  }

  // Customizable Area Start
  depositFormId: string = "";
  apiCallIdContractFormStatus: string = "";
  apiCallIdDespositFormStatus: string = "";
  apiCallIdDepositRequestlist: string = "";
  apiCallIdPurposeOfTransactions: string = "";
  apiCallIdDepositRequestId: string = "";

  async receive(from: string, message: Message) {

    const apiRequestCallIds = {
      [this.apiCallIdContractFormStatus]: this.getContractFormStatusResponse,
      [this.apiCallIdDespositFormStatus]: this.getDepositFormStatusResponse,
      [this.apiCallIdDepositRequestlist]: this.getDepositRequestListResponse,
      [this.apiCallIdPurposeOfTransactions]: this.getPurposeOfTransactionResponse,
      [this.apiCallIdDepositRequestId]: this.generateDepositRequestIdResponse

      // Add more API call IDs and handlers as needed
    };
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apierror = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.depositFormId) {
      if (apierror) {
        toast.error("Something Went Wrong");
      } else if (apiResponse.message === "Please signed your contract") {
        toast.success(apiResponse.message);
        const msg: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomForm");
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
      } else if (
        apiResponse.meta.message ===
        "Your deposit request has been submitted successfully"
      ) {
        toast.success(apiResponse.meta.message);
        this.setState({ sendRequest: true });
      } else {
        await setStorageData("depositCompleted", true);
        toast.warning(apiResponse.message);
      }
      // Disable submissions for the next 5 seconds
      setTimeout(() => {
        this.setState({ disabledAfterSend: false })
      }, 5000);
    }
    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](apiResponse, apierror);
    }
    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](apiResponse, apierror);
    }
  }

  // LifeCycle Medthod

  async componentDidMount() {
      this.getDepositRequestListRequest();
     this.getContractFormStatusRequest()
     this.getPurposeOfTransactionRequest();
      
  }


  // Request Functions

  depositFormPost = async (values: {
    email: string;
    account: string;
    remarks: string;
    transactionId: string;
    acoount_name: string;
    transaction_purpose: string;
    filename: string;
    file: File | null;
  }) => {
    if(!this.validateFile(values.file)) return;
    if(this.state.disabledAfterSend) return;
    this.setState({disabledAfterSend: true})
    const requestId = await getStorageData("depositRequestId");
    const formData = new FormData();
    formData.append("data[attributes][request_id]", requestId);
    formData.append("data[attributes][email]", values.email);
    formData.append("data[attributes][transaction_value]", values.account);
    formData.append("data[attributes][remarks]", values.remarks);
    formData.append("data[attributes][transaction_id]", values.transactionId);
    formData.append("data[attributes][name_in_bank_account]", values.acoount_name);
    formData.append(
      "data[attributes][purpose_of_transaction]",
      values.transaction_purpose
    );
    formData.append("data[attributes][transaction_slip]", values.file as Blob);
    formData.append("data[attributes][signed_contract_id]",this.state.signed_contract_id.toString())

    const headers = {
      contentType: configJSON.contentTypeFormData,
      token: localStorage.getItem("token"),
    };
    const depositFormAllData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    depositFormAllData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.depositFormId = depositFormAllData.messageId;
    depositFormAllData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.depositFormEndPoint
    );
    depositFormAllData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    depositFormAllData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(depositFormAllData.id, depositFormAllData);
  };

  getContractFormStatusRequest = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.contractFormStatusEndpoint,
      method: "GET",
    });

    this.apiCallIdContractFormStatus = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDepositFormStatusRequest = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.depositFormStatusEndpoint,
      method: "GET",
    });

    this.apiCallIdDespositFormStatus = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDepositRequestListRequest = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.listingDepositRequestEndpoint}?page=${this.state.paginationData.currentPage}&per_page=9`,
      method: "GET",
    });

    this.apiCallIdDepositRequestlist = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPurposeOfTransactionRequest = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.purposeOfTransactionsEndpoint}`,
      method: "GET",
    });

    this.apiCallIdPurposeOfTransactions = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  generateDepositRequestIdRequest = async () => {
    let token = await getStorageData("token");
    const header = {
        token
    };
    const formData = new FormData();
    formData.append("data[request_type]", "deposit_request");
    const requestMessage = ApiRequest({
        header: header,
        endPoint: configJSON.generateRequestIdEndpoint,
        payload: formData,
        method: "POST",
    });

    this.apiCallIdDepositRequestId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

  // Response Functions

  getContractFormStatusResponse = async (responseJson: any, errorResponse: any) => {
    const { data, message } = responseJson || {};
    const attributes = data?.attributes;
    const newUser = message === "No Contract Found";
    const formattedValue = (!attributes.request_id || attributes.request_id === "null") ? await getStorageData("depositRequestId") : attributes.request_id
  
    if (attributes) {
      const contractAmouont = attributes.contract_amount;
      const contractUserEmail = attributes.account?.data?.attributes?.email
      const secondPartyName = attributes.second_party_name;
      const statusMap: { [key: string]: TStatus } = {
        accepted: "approved",
        pending: "pending",
        rejected: "rejected",
      };
  
      const newStatus = statusMap[attributes.status] || "not started";
  
      this.setState({
        signed_contract_id: data.id,
        contractAmouont,
        contractUserEmail,
        secondPartyName,
        depositStages: initialDepositStages.map((stage, index) => {
          if (index === 0) {
            return { ...stage, status: stage.successStatus };
          } else if (index === 1) {
            return { ...stage, status: newStatus };
          }
          return stage;
        }),
        depositRequestId: formattedValue
      }, () => {
        if (newStatus === "approved") {
          this.getDepositFormStatusRequest();
        } else {
          this.checkButtonVisibility();
        }
      });
    }
  
    if (newUser) {
      this.setState({ depositStages: [] });
    }
  };
  

  getDepositFormStatusResponse = async (responseJson: any, errorResponse: any) => {
    this.checkButtonVisibility()
    const attributes = responseJson?.data?.attributes;
    const signed_contract_idFromDeposit = attributes?.signed_contract_id
    const formattedValue = (!attributes.request_id || attributes.request_id === "null") ? await getStorageData("depositRequestId") : attributes.request_id
    if (attributes) {
      let newStatus: TStatus;
      switch (attributes.status) {
        case "accepted":
          newStatus = "approved";
          break;
        case "pending":
          newStatus = "pending";
          break;
        case "rejected":
          newStatus = "rejected";
          break;
        default:
          newStatus = "not started";
      }
  
      if(Number(signed_contract_idFromDeposit) === Number(this.state.signed_contract_id)){
        this.setState((prevState) => ({
          depositStages: prevState.depositStages.map((stage, index) => 
            {
              if(index === 2) {
                return {...stage, status: stage.successStatus}
              } else if (index === 3) {
                return {...stage, status: newStatus}
              }
              return stage
            }
          ),
          depositRequestId: formattedValue
        }), this.checkButtonVisibility);
      }
      this.checkButtonVisibility()
    }
  }

  getDepositRequestListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const rows: IDepositRequestList[] = responseJson.data.map((item: any) => {
        const attributes = item.attributes;
        const formattedValue = (!attributes.request_id || attributes.request_id === "null") ? "N/A" : attributes.request_id
        return {
          date: formatDateToMonDayYear(attributes.date),
          transactionId: formattedValue,
          amount: `OMR ${attributes.transaction_value.toFixed(2)}`,
          status: attributes.status === 'accepted' ? 'completed' : attributes.status,
        };
      });
  
      const metaResponse = responseJson.meta;
  
      const pagination: IPaginationData = {
        currentPage: metaResponse?.current_page || 1,
        nextPage: metaResponse?.next_page || null,
        prevPage: metaResponse?.prev_page || null,
        totalPages: metaResponse?.total_pages || 1,
        currentCount: metaResponse?.current_count || rows.length,
        totalCount: metaResponse?.total_count || rows.length,
      };
  
      this.setState({ depositRequestList: rows, paginationData: pagination });
    }
  };

  getPurposeOfTransactionResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const transactionOptions: ITransactionOptions[] = responseJson.data.map((arr: string[]) => {
        return {
         label: arr[0],
         value: arr[1],
        };
      });
  
      this.setState({ transactionOptions });
    }
  };

  generateDepositRequestIdResponse = (responseJson: any, errorResponse: any) => {
    if(responseJson?.request_id) {
      this.setState({depositRequestId: responseJson.request_id}, async () => await setStorageData("depositRequestId", responseJson.request_id))
    }
  };

  depositSchema = () => {
    
    return Yup.object().shape({
      email: Yup.string().email().required("Please enter your email"),
      account: Yup.string().required("Please enter your amount"),
      transactionId: Yup.string().required("Please enter your transaction id"),
      acoount_name: Yup.string().required(
        "Please enter your name in bank account"
      ),
      transaction_purpose: Yup.string().required(
        "Please enter purpose of transaction"
      ),
      filename: Yup.string().required("No file attached"),
      file: Yup.mixed(),
    });
  };

  validateFile = (file: File | null) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
    const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];
    let errorMsg = '';
    let isValid = true;
    if(!file) {
      errorMsg = 'No file attached';
      isValid = false;
    } else if(file.size >= MAX_FILE_SIZE) {
      errorMsg = "File size must be less than or equal to 2MB";
      isValid = false;
    } else if(!ALLOWED_FILE_TYPES.includes(file.type)) {
      errorMsg = "Unsupported file format. Only JPG, PNG, and PDF are allowed.";
      isValid = false;
    }
    this.setState({fileErrorMsg: errorMsg})

    return isValid;
  }
  handleInputChange = (event: { target: { value: string } }, name:string) => {
    let changeValue = event.target.value;
    if ((name === 'account')) {
      changeValue = event.target.value.replace(/\D/g, '');
    } else if ((name === 'acoount_name' || name === "remarks")){
      changeValue = event.target.value.replace(/[^a-zA-Z\s]/g, '');
    }
    return changeValue
  }

  getErrorMessage = (
    touched: FormikTouched<TouchedType>,
    errors: FormikErrors<TouchedType>,
    value: string
  ) => {
    const style = {
      marginTop: "2px",
      fontSize: "12px",
      color: "#DC2626",
      fontFamily: "DIN Next LT Arabic Regular",
    };
    return (
      <Typography style={style}>
        {touched[value as keyof TouchedType] &&
          errors[value as keyof TouchedType]}
      </Typography>
    );
  };

  handleOpenPopup = () => {
    this.setState({ backPopup: !this.state.backPopup });
  };

  handleSendPopup = () => {
    this.setState({ sendRequest: !this.state.sendRequest });
  };

  handleClick = () => {
    return this.state.inputRef.current
      ? this.state.inputRef.current.click()
      : "";
  };

  handleBackClick = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "DepositRequest");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };


  formatDate = (date: Date) => {
    
    const day = ("0" + date.getDate()).slice(-2);
      const month =   date.toLocaleString("default", { month: "short" });
    const year =  date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  handleDateChange = (event: { target: { value: string } }) => {

    const dateValue = event.target.value;

      if (dateValue) {
      const date = new Date(dateValue);

        const formatted = this.formatDate(date);
      this.setState({ formattedDate: formatted });
    }
  };

  handleCondition = (
    condition: boolean | string | object | undefined,
    truePart: string  | undefined,
    falsePart: string | undefined
  )=> {
    return condition ? truePart : falsePart;
  };

  isPrevStageCompleted = (index: number) => {
    if(index === 0) return true;

    const current_index = index - 1;
    const current_stage = this.state.depositStages[current_index]

    return current_stage.status === current_stage.successStatus;
  }

  stepColor = (status: TStatus) => {
    const colors: {[key: string]: string} = {
      pending: "#F59E0B",
      approved: '#10B981',
      completed: '#10B981',
      rejected: '#EF4444',
      submitted: '#10B981',
      'fill form': '#1B4FE4',
      'not started': '#F1F5F9'
    }

    return colors[status] || '#F1F5F9';
  }

  getIconForStages = (index: number) => {
    const current_stage = this.state.depositStages[index];
    const isPrevStageCompleted = this.isPrevStageCompleted(index);
    const pendingIcon = isPrevStageCompleted && current_stage.status === 'pending'
    const initialInactive = !isPrevStageCompleted && (current_stage.status === 'not started' || current_stage.status === 'fill form')
    if(current_stage.status === current_stage.successStatus) {
    return current_stage.IconSuccess
    } 

    if(pendingIcon) {
      return current_stage.IconPending;
    }

    if(current_stage.status === 'rejected') {
      return current_stage.IconReject
    }
    if(initialInactive) {
      return current_stage.IconInit
    }
    if(isPrevStageCompleted) {
      return current_stage.IconInit
    }
  }

  navigateTo = (endpoint: string) => {
    const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
    goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), endpoint);
    goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToContractForm);
  }


  handlePaginatioChange = (event: any, next: number) => {
    this.setState((prevState) => ({
      paginationData: {
        ...prevState.paginationData,
        currentPage: next
      },
      depositRequestList: [],
    }), () => this.getDepositRequestListRequest())
  }
  

  addNewDepositRequest = () => {
    this.setState({ 
      depositStages: initialDepositStages,
      showButton: false
    }, async () => {
      this.checkButtonVisibility();
      this.generateDepositRequestIdRequest();
      await removeStorageData("contractCompleted");
      await removeStorageData("depositCompleted");
    });
  }

  checkButtonVisibility = () => {
    const { depositStages } = this.state;

    const anyRejected = depositStages.some(stage => stage.status === 'rejected');
    const allCompletedOrApproved = depositStages.every(stage =>
      stage.status === 'completed' || stage.status === 'approved' || stage.status === 'submitted'
    );
    const anyInProgress = depositStages.some(stage => 
      stage.status !== 'not started' && stage.status !== 'completed' && stage.status !== 'rejected'
    );

    if (anyRejected || allCompletedOrApproved || !anyInProgress) {
      this.setState({ showButton: true });
    } else {
      this.setState({ showButton: false });
    }
  };

  depositRequest = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DepositRequest"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleDisableCondition = (errors: any, values: any) => {
    const mandatoryFields = this.state.form.filter(field => field.isMandatory);
  
    // Check if all mandatory fields have values and no errors
    const isFormValid = mandatoryFields.every(field => {
      const fieldName = field.name as keyof typeof values;
      
      // Ensure the field has a value and doesn't have an error
      return values[fieldName] && !errors[fieldName];
    });
  
    return isFormValid;
  };
  // Customizable Area End
}
