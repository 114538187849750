// Customizable Area Start
import React from "react";
import { Box, TextField, Typography, Button, Card, IconButton, TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from "@material-ui/core";
import {
    styled
} from "@material-ui/core/styles";
import WithdrawRequestControllerWeb, {
    Props,
} from "./WithdrawRequestController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { searchIcon } from "./assets";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { capitalizeFirstLetters } from "../../../components/src/utils.web";
import { Pagination } from "@material-ui/lab";
import { TStatus } from "./MultipageFormsController.web";

// Customizable Area End

export default class WithdrawRequest extends WithdrawRequestControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    MainWrapper = styled(Box)(({ theme }) => ({
        zIndex:-1,
        position:"absolute",
        paddingTop: '4rem',
        [theme.breakpoints.up(900)]:{
          zIndex:0,
          position:"inherit"
        },
        "& .container": {
            [theme.breakpoints.down(900)]: {
              width: "calc(100vw - 100px)",
              padding: "5px 10px 10px 3rem",
              position: "absolute",
              top: "0",
              zIndex: 99,
            },
            [theme.breakpoints.down(750)]: {

            },
            minWidth: "100%",
            width: "calc(100vw - 493px)",
            padding: "4rem 10px 10px 2rem",
          },
        "& .statusContentFlexBox": {display: 'flex',gap: 10,flexDirection: 'column',[theme.breakpoints.down(750)]: {flexDirection: 'row'}},
        "& .iconLineFlexBox": {
            display: "flex",alignItems: "center",gap: '20px',
            marginBottom: '24px',[theme.breakpoints.down(750)]: {flexDirection: 'column'}},
        "& .horizontalDivider": {
            [theme.breakpoints.down(750)]: {height: '40px',width: '6px'},height: '6px',borderRadius: '5px',
            width: '100%',margin: '20px 0p'},
        "& .iconButton": {padding: theme.spacing(1)},
        "& .icon": {width: 24,height: 24},
        "& .buttonStyle": {textTransform: 'none',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '12px',            fontStyle: 'normal',
            fontWeight: 700,            lineHeight: '18px'
        },
        "& .stepFont": {color: '#64748B',            fontFamily: "DIN Next LT Arabic Regular",fontSize: '14px',fontStyle: 'normal',
            fontWeight: 400,            lineHeight: '22px'        },
        "& .titleFont": {
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            marginBottom: '16px'
        },
        "& .headingText": {
            [theme.breakpoints.down(750)]: {
                fontSize: '18px',
                lineHeight: '30px',
            },
            overflow: 'hidden',
            color: '#1E293B',
            textOverflow: 'ellipsis',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '40px',
            letterSpacing: '-0.15px',
        },
        "& .searchBarContainer": {

            [theme.breakpoints.down(750)]: {
                display: "none",
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '8px',
            borderRadius: '8px',
            border: '1px solid #64748B',
        },
        "& .searchBarInput": {
            width: '100%',
        },
        "& .mobileSearch": {
            [theme.breakpoints.down(750)]: {
              width: "30px",
              height: "30px",
            },            [theme.breakpoints.up(750)]: {
              display: "none",
            },
          },
        "& .customButton": {            [theme.breakpoints.down(900)]: {
                padding: "14px 8px"
            },
            [theme.breakpoints.down(750)]: {
                padding: '7px 5px !important',
                "& .MuiButton-startIcon": {                    marginRight: '0'
                }
            },
            display: 'flex',            padding: '10px 16px',            justifyContent: 'center',            alignItems: 'center',
            gap: '8px',            borderRadius: '8px',
            background: '#1B4FE4',
            width:"calc(60% - 50px)",
        },
        "& .buttonText": {            [theme.breakpoints.down(1451)]: {
                fontSize: "14px",
            },
            [theme.breakpoints.down(1370)]: {
                fontSize: "13px",            },
            [theme.breakpoints.down(1262)]: {                fontSize: "12px",
            },            [theme.breakpoints.down(900)]: {
                fontSize: "12px",            },            [theme.breakpoints.down(750)]: {
                display: 'none',
              },
            color: '#FFF',            textTransform: "none",fontFamily: "DIN Next LT Arabic Bold",fontSize: '16px',fontStyle: 'normal',
            fontWeight: 700,            lineHeight: '24px',
            flexWrap: "nowrap",
        },        "& .newRequestText": {            color: '#334155',
            fontFamily: "DIN Next LT Arabic Bold",            fontSize: '18px',
            fontStyle: 'normal',            fontWeight: 700,            lineHeight: '26px',            marginBottom: '24px',        },
        "& .transactionIdText": {
            color: '#334155',            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: '16px',            fontStyle: 'normal',
            fontWeight: 400,            lineHeight: '24px',
            marginBottom: '24px'        },
        "& .trasactionValueText": {
            color: "#1E293B",            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '18px',            fontStyle: 'normal',
            fontWeight: 700,            lineHeight: '26px',
        },
        "& .table": {
            minWidth: 650,
        },
        "& .depositHistoryText": {
            [theme.breakpoints.down(750)]:{
                fontSize: '18px'
            },
            color: '#1E293B',
            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '32px',  
            letterSpacing: '-0.12px',
            marginBottom: '8px'
        },
        "& .paginationRoot": {
            '& .MuiPaginationItem-root': {
                borderRadius: 4,
                marginRight: '16px',
                fontFamily: 'DIN Next LT Arabic Bold',
                fontWeight: 700,
            },
            '& .MuiPaginationItem-page:not(.Mui-selected)': {
                border: '1px solid #94A3B8', 
                color: '#94A3B8', 
            },
        },
        "& .headingStyle": {
            [theme.breakpoints.down(750)]: {
                fontSize: '16px'            },
            color: '#1E293B',            fontFamily: "DIN Next LT Arabic Bold",
            fontSize: '18px',            fontStyle: 'normal',
            fontWeight: 700,            lineHeight: '26px',
          },
        "& .tableContentText": {            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: '16px',            fontStyle: 'normal',
            fontWeight: 400,            lineHeight: '24px',
        },
        "& .tableBtn": {
            textTransform: 'capitalize',
            width: '120px',            padding: '12px 16px',
            fontFamily: "DIN Next LT Arabic Regular",            fontSize: '16px',
            fontStyle: 'normal',            fontWeight: 400,            lineHeight: '24px',
        },
        "& .amountText": {
            color: '#F59E0B',fontFamily: "DIN Next LT Arabic Regular",fontSize: '16px',fontStyle: 'normal',fontWeight: 400,lineHeight: '24px'},
        "& .headingFlexBox": {display: 'flex', justifyContent: 'space-between', marginBottom: '78'},
        "& .depositHistoryContainer": {marginTop: '96px'},
        "& .tableDeskTopView": {display: 'block',[theme.breakpoints.down(750)]: {display: 'none'}},
        "& .tableMobileView": {
            display: 'none',
            [theme.breakpoints.down(750)]: {display: 'block'}
        },
        "& .addIconStyle": {
            marginTop: '3px', color: "#fff",
            [theme.breakpoints.down(750)]: {fontSize: '24px'}
        },
        "& .responsiveCardContainer": {
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {flexDirection: 'column'},
        },
        "& .responsiveCard": {
            flexBasis: '600px',            flexGrow: 1,
            minWidth: '300px',            width: '100%',
            padding: 3,
            borderRadius: 1,
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',              },
        },
        "& .cardContent": {flexGrow: 1,padding: '8px 12px'},
        "& .stageCard": {
            flex: '1 1 300px',
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
                width: '100%'
              },
        },
        "& .stageCardContent": {
            padding: '24px',
            borderRadius: '8px',
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                flexDirection: 'column',
              },
        },
        "& .boxContainer": {
              [theme.breakpoints.down(1183)]: {
                top: 200,
              },
              [theme.breakpoints.down(975)]: {
                top: 220,
              },
              [theme.breakpoints.down(900)]: {
                top: 65,
                position: "fixed" as "fixed",
              },
            // width: "100%",
            position: "absolute" as "absolute",
            top: 180,
            right: 35,
            // height: "100%",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center" as "center",
            alignItems: "center",
            borderRadius:"8px"
            // background: "rgba(0, 0, 0, 0.6)",
          },
          "& .backBox": {
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column" as "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
            backgroundColor: "#FFF",
            top: 0,
            zIndex: 31,
            boxShadow:
              "0px 25px 50px 0px rgba(0, 0, 0, 0.15), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
        
          },
          "& .popupBox": {
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column" as "column",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            top: 0,
            boxShadow: "0px 2px 4px 0px #00000026",
            width: "fit-content",
            position:"relative" as "relative"
          },
          "& .profitData": {
            fontSize: "16px",
            fontFamily: "DIN Next LT Arabic Regular",
            padding: "12px 16px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: "#3C3E49",
            textTransform:"none",
            backgroundColor:"#ffffff",
          },
          "& .active": {
            backgroundColor: "#1B4FE4",
            color: "#F8FAFC",
            fontSize: "16px",
            fontFamily: "DIN Next LT Arabic Regular",
            padding: "12px 16px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            textTransform:"none",
          },
          "& .profitDatas2":{
            border:"2px solid red",
            backgroundColor:"#ffffff",
            width:"100%",
            textTransform:"none",
            color:"#000000",
            fontSize:"16px",
            fontFamily:"DIN Next LT Arabic Regular"
          }
    }));
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {withdrawStages, showButton, navigationLink, withdrawList, paginationData} = this.state;
        const color = this.stepColor(this.state.listingData?.attributes?.status as TStatus)
        return (
            <div style={{ display: "flex", width: "fit-content" }}>
                <Box>
                    <NavigationMenu id="" navigation={this.props.navigation}></NavigationMenu>
                </Box>
                <this.MainWrapper>
                {this.state.buttonPopup && (
                    // @ts-ignore
                  <Box className="boxContainer" ref={this.popupRef}>
                    <Box className="popupBox">
                      <Button
                        data-test-id="activeTab1"
                        className={this.handleCondition(this.state.activeTab === 1,"active","profitData")}
                        onClick={() => this.addNewWithdrawRequest('StandingForm', 1)}
                      >
                        Standing order withdrawal
                      </Button>
                      <Button
                        data-test-id="activeTab2"
                        className={this.handleCondition(this.state.activeTab === 2,"active","profitData")}
                        onClick={() => this.addNewWithdrawRequest('WithdrawForm', 2)}
                      >
                        One time withdrawal
                      </Button>
                    </Box>
                  </Box>
                )}
                    <Box className="container">
                        <Box className="headingFlexBox">
                            <Typography className="headingText">Withdraw Request</Typography>
                            <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px', alignItems: 'center' }}>
                                    <Box className="searchBarContainer">
                                        <TextField className="searchBarInput" placeholder="Type to search"
                                            InputProps={{
                                                startAdornment: (<img src={searchIcon} alt={'icon'} className="icon" />),
                                                style: {
                                                    padding: '5px',
                                                },
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Box>
                                    <img src={searchIcon} className="mobileSearch" />
                                
                                {
                                    showButton && (
                                        <Button
                                            data-test-id="showAddBtn"
                                            onClick={this.handleButtonPopup}
                                            startIcon={<AddCircleOutlineIcon
                                                className="addIconStyle" />}
                                            className="customButton">
                                            <span className="buttonText">New Withdraw Request</span>
                                        </Button>
                                    )
                                }
                            </Box>
                        </Box>
                        {
                            withdrawStages.length > 0 && (
                                <Box>
                                    <Typography className="newRequestText">New Request Progress</Typography>
                                    <Typography className="transactionIdText">Request ID: <span className="trasactionValueText">{this.state.requestId}</span></Typography>
                                    <Box className="responsiveCardContainer">
                                        {
                                            withdrawStages.map((item, index) => {
                                                const customStyle: any = {

                                                };
                                                const customBorderStyle: any = {}
                                                let isPrevStageCompleted = this.isPrevStageCompleted(index);
                                                let color = !isPrevStageCompleted ? '#64748B' : this.stepColor(item.status);
                                                let border = item.status !== item.successStatus && isPrevStageCompleted ? `2px solid ${color}` : 'none'
                                                const isButtonEnabled = item.status === "fill form";
                                                if (!isPrevStageCompleted) {customBorderStyle.border = '1px solid gray'
                                                 customBorderStyle.background = "#fff"}
                                                if (isButtonEnabled) {
                                                    customStyle.color = '#fff'
                                                    customStyle.background = color
                                                }
                                                return (
                                                    <Card style={{ border }} className="stageCard" elevation={0}>
                                                        <Box className="stageCardContent">
                                                            <Box className="statusContentFlexBox">
                                                                <Box className="iconLineFlexBox">
                                                                    <IconButton style={{ backgroundColor: color, ...customBorderStyle }} className="iconButton">
                                                                        <img src={this.getIconForStages(index)} alt={'icon'} className="icon" />
                                                                    </IconButton>
                                                                    {index < 1 && <Box style={{ backgroundColor: color }} className="horizontalDivider" />}
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="stepFont">{item.title}</Typography>
                                                                    <Typography className="titleFont">{item.description}</Typography>
                                                                    <Button
                                                                        data-test-id="formFillBtn"
                                                                        onClick={isButtonEnabled ? () => this.navigateTo(navigationLink) : undefined}
                                                                        style={{ color: color, backgroundColor: `${color}30`, ...customStyle }}
                                                                        className="buttonStyle"
                                                                    >
                                                                        {capitalizeFirstLetters(item.status)}
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </Box>
                                </Box>
                            )
                        }
                        <Box className="depositHistoryContainer">
                            <Typography className="depositHistoryText">WithDrawal Request History</Typography>
                            <TableContainer className="tableDeskTopView">
                                <Table className="table" aria-label="simple table">
                                    <TableHead style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                                        <TableRow>
                                            <TableCell className="headingStyle">Request date</TableCell>
                                            <TableCell className="headingStyle" align="center">Request ID</TableCell>
                                            <TableCell className="headingStyle" align="center">Money Withdrew</TableCell>
                                            <TableCell className="headingStyle" align="center">Wallet Balance</TableCell>
                                            <TableCell className="headingStyle" align="center">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.listingData?.length === 0 && <Typography style={{width: '100%', textAlign: 'center'}} className="tableContentText">No data found</Typography>}
                                           
                                                <TableRow key={this.state.listingData?.attributes?.transactionId}>
                                                    <TableCell className="tableContentText" component="th" scope="row">
                                                        {this.state.listingData?.attributes?.preferred_date}
                                                    </TableCell>
                                                    <TableCell className="tableContentText" align="center">{this.state.listingData?.attributes?.request_id}</TableCell>
                                                    <TableCell className="amountText" align="center">{this.state.listingData?.attributes?.amount}</TableCell>
                                                    <TableCell className="amountText" align="center">{this.state.listingData?.attributes?.wallet_balance}</TableCell>
                                                    <TableCell className="stepFont" align="center">
                                                    <Button
                                                     style={{ backgroundColor: `${color}30`}}
                                                     className="tableBtn">{this.state.listingData?.attributes?.status}
                                                     </Button>
                                                    </TableCell>
                                                 </TableRow>
                                        {
                                            paginationData.totalPages > 1 && (
                                                <TableRow>
                                                    <TableCell colSpan={5}>
                                                        <Box display="flex" justifyContent="flex-end">
                                                            <Pagination
                                                                data-test-id="pagination"
                                                                count={paginationData.totalPages}
                                                                page={paginationData.currentPage}
                                                                onChange={(event, page) => this.handlePaginatioChange(event, page)}
                                                                color="primary"
                                                                size="small"
                                                                className="paginationRoot"
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box className="tableMobileView">
                                <Box
                                    className="responsiveCardContainer"
                                >
                                    {this.state.listingData?.length === 0 && <Typography style={{width: '100%', textAlign: 'center'}} className="tableContentText">No data found</Typography>}
                                    {/* {withdrawList?.map((row, index) => {
                                        const color = this.stepColor(row?.status as TStatus)
                                        return ( */}
                                            <Card elevation={0} className="responsiveCard">
                                                <Box
                                                    className="cardContent"
                                                    // style={{
                                                    //     backgroundColor: (index % 2 === 0) ? '#F1F5F9': 'none',
                                                    // }}
                                                >
                                                    {['Request date', 'Request ID', 'Money Withdrew', 'Wallet Balance', 'Status'].map((label, idx) => (
                                                        <Box
                                                            key={idx}
                                                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                                                        >
                                                            <Typography className="headingStyle">{label}</Typography>
                                                            <Typography className="tableContentText">
                                                                {label === 'Request date' && this.state.listingData?.attributes?.preferred_date}
                                                                {label === 'Request ID' && this.state.listingData?.attributes?.request_id}
                                                                {label === 'Money Withdrew' && (<Typography className="amountText">{this.state.listingData?.attributes?.amount}</Typography>)}
                                                                {label === 'Wallet Balance' && (<Typography className="amountText">{this.state.listingData?.attributes?.wallet_balance}</Typography>)}
                                                                {label === 'Status' && (
                                                                    <Button
                                                                        style={{ color, backgroundColor: `${color}30` }}
                                                                        className="tableBtn"
                                                                    >
                                                                        {this.state.listingData?.attributes?.status}
                                                                    </Button>
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Card>
                                        {/* );
                                    })} */}
                                </Box>
                                {
                                    paginationData.totalPages > 1 && (
                                        <Box marginTop={2} display="flex" justifyContent="flex-end">
                                            <Pagination
                                                data-test-id="pagination" count={paginationData.totalPages} page={paginationData.currentPage} 
                                                onChange={(event, page) => this.handlePaginatioChange(event, page)}
                                                color="primary" size="small" className="paginationRoot"
                                            />
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>                        

                    </Box>
                </this.MainWrapper>
            </div>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End
