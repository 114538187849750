import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { lineFour, lineOne, lineThree, lineTwo } from "./assets";
import React from "react";
interface ApiCallData {
  contentType?: string,
  method: string,
  endPoint: string,
  body?: Object,
  type?: string,
  token: string
}
interface Account {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: string | null;
  last_visit_at: string;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  role_id: string | null;
  full_name: string;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  is_local_admin: boolean;
  id_proof: string;
  service_type: string;
  country: string;
  photo_information: string | null;
  language: string;
}
interface Attributes {
  id: number;
  created_by: number;
  headings: string;
  contents: string;
  app_url: string | null;
  status : string | null;
  is_read: boolean;
  read_at: string | null;
  created_at: string;
  updated_at: string;
  account: Account;
}
interface Notification {
  id: string;
  type: string;
  attributes: Attributes;
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  totalProfitPopup: boolean;
  activeTab: number;
  isDialogOpen: boolean;
  anchorEl : HTMLElement | null;
  selectedNotificationId: string | null;
  data: Notification[];
  profitList: {label: string, value: string}[];
  investmentData: {
    name: string,
    percentage: string,
    total: string,
    image: string,
    color: string
  }[];
  transactionsActivity: {
    date: string,
    type: string,
    amount: string,
    balance: string,
    amountColor: string,
  }[];
  assestActivity: {
    class: string,
    current: string,
    target: string,
    balance: string,
    currentColor: string,
    amountColor: string,
  }[];
  startDate: string;
  activeCircle: number,
  inputRef: any;
  activeData: {
    first: number
  }
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAllNotificationsCallId : string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  markAllAsReadId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      startDate: "",
      activeTab: 0,
      totalProfitPopup: false,
      isDialogOpen : false,
      anchorEl:null,
      selectedNotificationId: null,
      data: [],
      profitList: [
        {
          label: "Total Profit",
          value: "Total Profit"
        },
        {
          label: "Avg Profit",
          value: "Avg Profit"
        },
        {
          label: "Total Returns",
          value: "Total Returns"
        },
        {
          label: "Avg in Returns",
          value: "Avg in Returns"
        },
      ],
      inputRef: React.createRef(),
      activeData: {
        first: 0,
      },
      investmentData: [
        {
          name: "Total Investments",
          percentage: "-18%",
          total: "$357,000",
          image: lineOne,
          color: "#EF4444"
        },
        {
          name: "Total Profits",
          percentage: "+2.5%",
          total: "$400,000",
          image: lineTwo,
          color: "#F59E0B"
        },
        {
          name: "Total Returns",
          percentage: "+25%",
          total: "$390,000",
          image: lineThree,
          color: "#10B981"
        },
        {
          name: "Avg in Return",
          percentage: "+50%",
          total: "$399,000",
          image: lineFour,
          color: "#1B4FE4"
        },
      ],
      activeCircle: 0,
      transactionsActivity: [
        {
          date: "Jan 04, 2024",
          type: "Withdraw",
          amount: "-15000",
          balance: "$64900",
          amountColor: "#EF4444",
        },
        {
          date: "Jan 05, 2024",
          type: "Deposit",
          amount: "-21000",
          balance: "$85900",
          amountColor: "#EF4444",
        },
        {
          date: "Jan 05, 2024",
          type: "Withdraw",
          amount: "+20000",
          balance: "$74900",
          amountColor: "#10B981",
        },
        {
          date: "Jan 05, 2024",
          type: "Deposit",
          amount: "+20000",
          balance: "$84900",
          amountColor: "#10B981",
        },
      ],
      assestActivity: [
        {
          class: "Lorem",
          current: "10%",
          target: "15%",
          balance: "$75000",
          currentColor: "#EF4444",
          amountColor: "#EF4444",
        },
        {
          class: "Lorem",
          current: "20%",
          target: "10%",
          balance: "$75000",
          currentColor: "#F59E0B",
          amountColor: "#EF4444",
        },
        {
          class: "Lorem",
          current: "20%",
          target: "10%",
          balance: "$75000",
          currentColor: "#10B981",
          amountColor: "#EF4444",
        },
        {
          class: "Lorem",
          current: "20%",
          target: "10%",
          balance: "$75000",
          currentColor: "#EF4444",
          amountColor: "#EF4444",
        },
      ]

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getAllNotificationsData();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
      this.handleResForGetAllNotification(from, message)
      this.handleResForMarksAllRead(from, message)
      this.handleResForDeleteNotification(from, message)
      this.handleResForMarkAllAsRead(from,message)
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleCalendarOpen = () => {
    if (this.state.inputRef.current) {
      this.state.inputRef.current.showPicker();
    }
  };

  right() {
    const newActive = (this.state.activeCircle + 1) % this.state.investmentData.length;
    this.setState({
      activeData: { first: newActive },
      activeCircle: newActive
    });
  }

  setActiveCircle(index: any) {
    this.setState({
      activeData: { first: index },
      activeCircle: index
    });
  }

  async getAllNotificationsData() {
    const token = await getStorageData("token");

    const webHeader = {
      token,
      "Content-Type": webConfigJSON.dashboarContentType,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllNotificationsCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.notificationData
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  apiCall = async (data: ApiCallData) => {
    const { contentType, token, method, endPoint, body, type } = data;
    const header = {
      token,
      "Content-Type": contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async handleMarkAsRead() {
    const notification = this.state.selectedNotificationId;
    if (notification) {
      const token = await getStorageData("token");

      this.markAsReadCallId = await this.apiCall({
        token: token,
        method: webConfigJSON.markAsReadMethod,
        endPoint: `${webConfigJSON.notificationData}/${notification}`,
        contentType: webConfigJSON.dashboarContentType
      })
      this.handleOptionClose()
    }
  }

  async handleDeleteNotification() {
    const notification = this.state.selectedNotificationId;
    if (notification) {
      const token = await getStorageData("token");
      this.deleteCallId = await this.apiCall({
        token: token,
        method: webConfigJSON.deleteMethod,
        endPoint: `${webConfigJSON.notificationData}/${notification}`,
        contentType: webConfigJSON.dashboarContentType
      })
      this.handleOptionClose()
    }
  }

  async handleMarkAllAsRead() {
    const token = await getStorageData("token");
    const notificationIds = this.state.data?.map(item => item.id);
    const body = {
      "notification": {
        "notification_ids": notificationIds
      }
    }
    this.markAllAsReadId = await this.apiCall({
      token: token,
      method: webConfigJSON.markAsReadMethod,
      endPoint: `${webConfigJSON.notificationData}/mark_selected_as_read`,
      body: body,
      contentType: webConfigJSON.dashboarContentType,
    })
  }

  handleClick = () => {
    this.setState({ totalProfitPopup: !this.state.totalProfitPopup })
  }

  handleDialogOpen = () => {
    this.setState({ isDialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  handleOptionOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    this.setState({ anchorEl: event.currentTarget, selectedNotificationId: id });
  };

  handleOptionClose = () => {
    this.setState({ anchorEl: null, selectedNotificationId: null });
  };

  handleNavigation = (name: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), name);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  downloadFile = (fileUrl: string) => {
    const docFile = new XMLHttpRequest();
    docFile.open('GET', fileUrl, true);
    docFile.responseType = 'blob';
    docFile.onload = () => {
      if (docFile.status === 200) {
        const blob = docFile.response;
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = 'Fundsmen_document';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };
    docFile.send();
  };

  handleResForGetAllNotification = async (from: string, message: Message) => {
    if (this.getAllNotificationsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ data: responseJson.data })
      }
    }
  }

  handleResForMarksAllRead = async (from: string, message: Message) => {
    if (this.markAsReadCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.getAllNotificationsData()
      }
    }
  }

  handleResForDeleteNotification = async (from: string, message: Message) => {
    if (this.deleteCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.getAllNotificationsData()
      }
    }
  }

  handleResForMarkAllAsRead = async (from: string, message: Message) => {
    if (this.markAllAsReadId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.getAllNotificationsData()
      }
    }
  }
  // Customizable Area End
}
