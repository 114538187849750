Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms";
exports.labelBodyText = "MultipageForms Body";
exports.contentTypeFormData = "multipart/form-data";
exports.btnExampleTitle = "CLICK ME";
exports.successMessage = "UserDetails inserted successfully";
exports.errorsDataMsg  = "Internal Server error";
exports.multiPageFormSubmitEndPoint = "/bx_block_multipageforms/user_profiles";
exports.depositFormEndPoint = "bx_block_multipageforms2/deposit_requests";
exports.withdrawFormEndPoint ="bx_block_multipageforms2/withdraw_requests";
exports.contractFormStatusEndpoint = "bx_block_custom_form/signed_contracts/get_last_contract";
exports.depositFormStatusEndpoint = "bx_block_multipageforms2/deposit_requests/get_last_deposit_request";
exports.standingFormListingEndpoint = "bx_block_multipageforms2/withdraw_requests/get_last_withdraw_request";
exports.listingDepositRequestEndpoint = "bx_block_multipageforms2/deposit_requests"
exports.purposeOfTransactionsEndpoint = "bx_block_multipageforms2/deposit_requests/get_purpose_of_transactions";
exports.listingWithdrawRequestEndpoint = "bx_block_multipageforms2/withdraw_requests";
exports.lastWithdrawFormStatusEndpoint = "bx_block_multipageforms2/withdraw_requests?sort_by=id&order=desc&page=1&per_page=1"
exports.generateRequestIdEndpoint = "bx_block_multipageforms2/deposit_requests/generate_request_id"
exports.standingFormPostRequest = "bx_block_multipageforms2/withdraw_requests"
// Customizable Area End