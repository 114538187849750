// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import  { InteractiveCalWeb } from "../../blocks/interactivecalculator/src/Interactivecalculator.web";
import Interactivecalculator2 from "../../blocks/interactivecalculator2/src/Interactivecalculator2";
import Share from "../../blocks/share/src/Share";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Search from "../../blocks/search/src/Search";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import RecoveryPassword from "../../blocks/forgot-password/src/RecoveryPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import {TermsConditionsWeb} from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import {UserProfileBasicBlockWebHoc} from "../../blocks/user-profile-basic/src/UserProfileBasicBlockWeb.web";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Notifications from "../../blocks/notifications/src/Notifications";
import {EmailAccountRegistrationWeb} from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu.web";
import { LandingPageWeb } from "../../blocks/landingpage/src/LandingPage.web";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank.web";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Linkedaccounts2 from "../../blocks/linkedaccounts2/src/Linkedaccounts2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import { ToastContainer } from "react-toastify";
import CustomForm from "../../blocks/customform/src/CustomForm.web"
import MultipageForms from "../../blocks/multipageforms/src/MultipageForms.web"
import WithdrawForms from "../../blocks/multipageforms/src/WithdrawForms.web"
import StandingForms from "../../blocks/multipageforms/src/StandingOrderForms.web"
import "react-phone-input-2/lib/style.css";
import DepositRequestWeb from "../../blocks/multipageforms/src/DepositRequest.web";
import WithdrawRequestWeb from "../../blocks/multipageforms/src/WithdrawRequest.web";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import Feedback from "../../blocks/contactus/src/Feedback.web";
import HelpCentre from "../../blocks/user-profile-basic/src/HelpCentre.web"
import { TransactionStatementWeb } from "../../blocks/visualanalytics/src/VisualAnalytics.web"

const routeMap = {

AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Interactivecalculator2:{
 component:Interactivecalculator2,
path:"/Interactivecalculator2"},
Interactivecalculator:{
  component:InteractiveCalWeb,
 path:"/Interactivecalculator"},
Share:{
 component:Share,
path:"/Share"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Search:{
 component:Search,
path:"/Search"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
RecoveryPassword:{
  component:RecoveryPassword,
 path:"/RecoveryPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TermsConditions:{
 component:TermsConditionsWeb,
path:"/TermsConditions"},
PrivacyPolicy:{
  component:TermsConditionsWeb,
 path:"/PrivacyPolicy"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
QuestionBank:{
  component:QuestionBank,
 path:"/QuestionBank"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlockWebHoc,
path:"/UserProfileBasicBlock"},
HelpCentre:{
  component:HelpCentre,
 path:"/HelpCentre"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Dashboard:{
  component: Dashboard,
  path: "/Dashboard"
},
TransactionStatement:{
  component: TransactionStatementWeb,
  path: "/TransactionStatement"
},
DepositForm:{
  component: MultipageForms,
  path: "/DepositForm"
},
DepositRequest:{
  component: DepositRequestWeb,
  path: "/DepositRequest"
},
WithdrawRequest:{
  component: WithdrawRequestWeb,
  path: "/WithdrawRequest"
},
WithdrawForm:{
  component: WithdrawForms,
  path: "/WithdrawForm"
},
ContactUs:{
  component: Contactus,
  path:"/contactUs"
},
Feedback:{
  component: Feedback,
  path:"/Feedback"
},
StandingForm:{
  component: StandingForms,
  path: "/StandingForm"
},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
CustomForm:{
  component:CustomForm,
  path:"/CustomForm"
},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
EmailAccountRegistration:{
 component:EmailAccountRegistrationWeb,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Multilevelapproval2:{
 component:Multilevelapproval2,
path:"/Multilevelapproval2"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPageWeb,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Linkedaccounts2:{
 component:Linkedaccounts2,
path:"/Linkedaccounts2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: LandingPageWeb,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const isScrollable = ['/DepositRequest', '/DepositRequest/AlertWeb', '/Dashboard/AlertWeb', '/Dashboard', 
    '/TransactionStatement/AlertWeb','/TransactionStatement', '/UserProfileBasicBlock/AlertWeb', 
    '/UserProfileBasicBlock','/WithdrawRequest/AlertWeb','/WithdrawRequest', '/HelpCentre', '/HelpCentre/AlertWeb']
    
    return (
      <View style={{ height: '100vh', width: '100vw', overflow: isScrollable.includes(window.location.pathname) ? 'hidden' : 'scroll' }}>
        {/* <TopNav /> */}
        <ToastContainer />
        {WebRoutesGenerator({ routeMap })}
        {/* <ModalContainer /> */}
      </View>
    );
  }
}

export default App;