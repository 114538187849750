import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, crossIconButton,editButtonIcon, verifyIcon, editIcon, flagGerman, flagUK, uploadIconSmall } from "./assets";
import React from "react";
import { getStorageData, removeStorageData} from "../../../framework/src/Utilities";
import ApiRequest from "../../../components/src/ApiRequest.web";
import { toast } from "react-toastify";
const { baseURL } = require("../../../framework/src/config.js");

export interface IFAQ {
  id: string;
  question: string;
  description: string;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  feedbackNav?: any;
  i18n: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  countryName:string;
  currentCountryCode: string;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: string;
  newPasswordText: string;
  reTypePasswordText: string;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;

  editButtonIcon:string;
  tabValue:number;
  verifyIcon:string;
  editIcon:string;
  investmentService:string;
  idProof:string;
  inputRef: any;
  flagGerman:string;
  flagUK:string;
  uploadIconSmall:string;
  editFormEnable:boolean;
  editPasswordFormEnable:boolean;
  passwordValidationMessage:string;
  fullPhoneNumber : string;
  activated:boolean;
  profileImgUrl : string;
  crossIconButton : string;
  profileImgFileName : string;
  documentImgUrl : string;
  documentImgFileName : string;
  cancelDocBox : boolean;
  currentPasswordError:string;
  newPasswordError:string;
  confirmPasswordError:string;
  passwordError:string;
  open:boolean;
  imagePreviewUrl:string | null;
  baseUrlLink : string;
  enableCurrentPasswordFieldEye:boolean;
  enableNewPasswordFieldEye:boolean;
  enableConfirmPasswordFieldEye:boolean;
  formikRef:any;
  tokenExpireStatus:boolean;
  currentLanguage: string;
  checkedSwitchArabic:boolean;
  checkedSwitchEnglish:boolean;
  popupForLogout: boolean;
  feedback: boolean;
  investorDescription: string;
  freqAskQuestion: IFAQ[];
  activeAnswerIndex: string;
  openContactUs: boolean;
  deactivatePopup:boolean;
  profilePopup:boolean;
  customError: string
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiCallIdLanguageUpdate: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  getUserProfileCallId : unknown;
  passwordUpdateApiCallId : unknown;
  userDetailsUpdateApiCallId : unknown;
  userProfileImageUpdateApiCallId:unknown;
  userAttr: any;
  apiCallIdFaq: string = "";
  deactiveId: string =""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
   
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      countryName:'',
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      enableCurrentPasswordFieldEye:true,
      enableNewPasswordFieldEye:true,
      enableConfirmPasswordFieldEye:true,
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: true,
      editButtonIcon: editButtonIcon,
      tabValue:0,
      verifyIcon:verifyIcon,
      editIcon:editIcon,
      investmentService:"",
      idProof:"",
      inputRef: React.createRef(),
      flagGerman :flagGerman,
      flagUK:flagUK,
      uploadIconSmall:uploadIconSmall,
      crossIconButton:crossIconButton,
      editFormEnable:true,
      editPasswordFormEnable:false,
      passwordValidationMessage:'',
      fullPhoneNumber : '',
      activated:false,
      profileImgUrl : '',
      profileImgFileName : '',
      documentImgUrl : '',
      documentImgFileName : '',
      cancelDocBox : false,
      currentPasswordError:'',
      newPasswordError:'',
      confirmPasswordError:'',
      passwordError:'',
      open:false,
      imagePreviewUrl:null,
      baseUrlLink:baseURL,
      formikRef : React.createRef(),
      tokenExpireStatus:false,
      currentLanguage: this.props.i18n?.language,
      checkedSwitchArabic: this.props.i18n?.language === 'ar',
      checkedSwitchEnglish: this.props.i18n?.language === 'en',
      popupForLogout: false,
      feedback: false,
      investorDescription: '',
      freqAskQuestion: [],
      activeAnswerIndex: "",
      openContactUs: false,
      deactivatePopup: false,
      profilePopup: false,
      customError:""
    };
    
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
  this.handleDeactivateAccount(message);
   this.handleNavigationMessage(message);
    runEngine.debugLog("on receive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
    
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getUserProfileCallId:
            this.getReceiveUpdateProfile(responseJson);
            this.parseApiCatchErrorResponse(errorReponse);
            break;
    
          case this.passwordUpdateApiCallId:
            this.getReceiveUpdatePassword(responseJson);
            break;
    
          case this.userDetailsUpdateApiCallId:
            if (responseJson != null && responseJson.errors === undefined) {
              this.enableDisableEdit();
              this.getUserProfileData();
            }
            break;
    
          case this.userProfileImageUpdateApiCallId:
            this.getReceiveUpdateImageProfile(responseJson);
            break;
    
          default:
            break;
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    
    
    }

    const apiRequestCallIds = {
      [this.apiCallIdFaq]: this.getFAQResponse,
      [this.apiCallIdLanguageUpdate]: this.handleLanguageChangeResponse,
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const apiError = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )

    if(apiRequestCallIds !== null && apiRequestCallIds[apiRequestCallId]){
      apiRequestCallIds[apiRequestCallId](apiResponse, apiError)
    }
    
    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start

  async componentDidMount() {
   await this.getUserProfileData();
   await this.getFAQRequest();
  }
  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
     onChangeText: (text:string) => 
     {
       if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })
     
        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
       }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType:"tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps  = {
    value: "",
    editable: true,
    onChangeText: (text:string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps  = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
  ? this.txtInputEmailWebProps
  : this.txtInputEmailMobileProps;
  
  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => { 
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ 
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry });
        this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
        this.imgNewPasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText:(text:string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({ 
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry });
        this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
        this.imgReTypePasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }
  
  handleChange = (event: React.ChangeEvent<{}>, tabValue: number) => {
   this.setState({tabValue:tabValue})
  };
  
  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };
  handleClick = () => {
    return this.state.inputRef.current
      ? this.state.inputRef.current.click()
      : "";
  };

  handleTranslationChange = (keyName: string) => {
    return this.props.i18n?.t(keyName)
  }
  
  enableDisableEdit() {
    this.setState({editFormEnable:!this.state.editFormEnable})
    this.setState({editPasswordFormEnable:false})
   
  }
  enableDisableEditPassowrd() {
    this.setState({editPasswordFormEnable:!this.state.editPasswordFormEnable})
    this.setState({editFormEnable:true})
    this.setState({currentPasswordText:'',newPasswordText:'',reTypePasswordText:''})
    if (this.state.formikRef.current) {
      this.state.formikRef.current.resetForm();
    }
   
  }
 
  async getUserProfileData(){
    let token= await getStorageData("token");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailsApi
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }
  async validateAndUpdateProfilePassword(values:any) {
    let token= await getStorageData("token");
  

    let currentPwd = values.currentPasswordText;
    let newPwd = values.newPasswordText;
    let reTypePwd = values.reTypePasswordText;
    let header;
    header = {
            "token": token
          };
    let formData = new FormData();

    formData.append('data[current_password]', currentPwd);
    formData.append('data[new_password]', newPwd);
    formData.append('data[confirm_password]', reTypePwd);
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.passwordUpdateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_profile/passwords'
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }
 

  handleClose = () => {
    this.setState({ open: false });
  };
  async updateUserProfile(values:any) {
    let token= await getStorageData("token");
    let email = values.email;
    let firstName = values.firstName;
    let lastName = values.lastName;
    let currentCountryCode = values.currentCountryCode;
    let phoneNumber = values.phoneNumber? values.phoneNumber: "";
    let idProof = values.idProof ;
    let investmentService = values.investmentService;
    let countryName = values.countryName;
    let documentImgUrl =values.file;
    let header = {
      "token": token
    };
    let formData = new FormData();
    formData.append('data[attributes][first_name]',firstName);
    formData.append('data[attributes][last_name]', lastName);
    formData.append('data[attributes][email]', email);
    formData.append('data[attributes][country_code]', currentCountryCode);
    formData.append('data[attributes][phone_number]', phoneNumber);
    formData.append('data[attributes][id_proof]', idProof);
    formData.append('data[attributes][service_type]', investmentService);
    formData.append('data[attributes][country]', countryName);
    (typeof documentImgUrl!="string")  && formData.append('data[attributes][document]', documentImgUrl);
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.userDetailsUpdateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.userDetailsUpdateApi
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }
  async updateUserProfileImg(values:any) {
    let token= await getStorageData("token");
    let documentImgUrl =values.file;
    let header = {
      "token": token
    };
    let formData = new FormData();
    (typeof documentImgUrl!="string")  && formData.append('account[profile_image]', documentImgUrl);
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.userProfileImageUpdateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.userProfileImageUpdateApi
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }
  handleClickOpenUploadBox = () =>{
    this.setState({open:true});
  }
  handleClickCloseUploadBox = () =>{
    this.setState({open:false});
  }
  handleRetry = () =>{
    this.setState({imagePreviewUrl:null})
  }
  handleClickShowCurrentPasswordEye = () =>{
    this.setState({
      enableCurrentPasswordFieldEye: !this.state.enableCurrentPasswordFieldEye,
    });
  };
  handleClickShowNewPasswordEye = () =>{
    this.setState({
      enableNewPasswordFieldEye: !this.state.enableNewPasswordFieldEye,
    });
  };

  handleLogoutPopup = () => {
    this.setState({popupForLogout: !this.state.popupForLogout})
  }
  handleClickShowConfirmPasswordEye = () =>{
    this.setState({
      enableConfirmPasswordFieldEye: !this.state.enableConfirmPasswordFieldEye,
    });
  };
  getReceiveUpdateProfile(responseJson?:any){
   
    if (
      responseJson &&
      !responseJson.errors &&
      responseJson.data &&
      responseJson.data.attributes
    ) {
      

      this.userAttr = responseJson.data.attributes;
      

      if (this.userAttr !== null && this.userAttr !== undefined) {
        let email = this.userAttr.email;
        let firstName = this.userAttr.first_name;
        let lastName = this.userAttr.last_name;
        let currentCountryCode = this.userAttr.country_code;
        let phoneNumber = this.userAttr.phone_number? this.userAttr.phone_number: "";
        let fullPhoneNumber = this.userAttr.full_phone_number;
        let activated = this.userAttr.activated;
        let idProof = this.userAttr.id_proof;
        let investmentService = this.userAttr.service_type;
        let countryName = this.userAttr.country;
        let profileImgUrl = this.userAttr.profile_image?.url;
        let profileImgFileName = this.userAttr.profile_image?.filename;
        let documentImgUrl = this.userAttr.document.url;
        let documentImgFileName = this.userAttr.document.filename;
        this.setState({
          email: email,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          currentCountryCode : currentCountryCode,
          idProof : idProof,
          investmentService : investmentService ,
          countryName : countryName,
          fullPhoneNumber : fullPhoneNumber,
          activated:activated,
          profileImgUrl : profileImgUrl,
          profileImgFileName : profileImgFileName,
          documentImgUrl : documentImgUrl,
          documentImgFileName : documentImgFileName

        });
     }
    } else {
   
      if (
        responseJson.errors &&
        responseJson.errors.length > 0 &&
        responseJson.errors[0].token
      ) {
        this.showAlert("Session Expired", "Please Log in again.");
        this.setState({tokenExpireStatus:true});
        const goTologin = new Message(getName(MessageEnum.NavigationMessage));
        goTologin.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        goTologin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(goTologin);

      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }

  }
  getReceiveUpdateImageProfile(responseJson?:any){
    if (responseJson != null && responseJson.errors === undefined) {
      this.setState({open:false,imagePreviewUrl:null});
      this.getUserProfileData();
      window.location.reload();
  } 
    
  }
  getReceiveUpdatePassword(responseJson?:any){
   
   
    if (responseJson != null && responseJson.errors === undefined) {
    
      if (responseJson.message && responseJson.data === undefined) {
        this.setState({
          inputRef: React.createRef(),
          imagePreviewUrl: null,
          passwordError: '',
          currentPasswordText: '',
          newPasswordText: '',
          reTypePasswordText: ''
        });
        this.enableDisableEditPassowrd();
        this.getUserProfileData();
      }
    
      const { password, current_password_error, confirm_password_error, new_password_error } = responseJson.data || {};
    
      switch (password) {
        case "must contain at least one uppercase letter, one lowercase letter, one number, and be at least 8 characters long":
          this.setState({ passwordError: this.handleCondition(this.props.i18n.language === 'ar' , 'يجب أن تحتوي على حرف كبير واحد على الأقل، وحرف صغير واحد، ورقم واحد، وألا يقل طولها عن 8 أحرف' , password )});
          break;
        case "":
          break;  
        case "":
          break;  
        default:
          this.setState({ passwordError: '' });
          break;
      }
    
      switch (current_password_error) {
        case 'You have entered wrong password':
          this.setState({ currentPasswordError: this.handleCondition(this.props.i18n.language === 'ar' , 'لقد ادخلت كلمة مرور خاطئة' , current_password_error )});
          break;
        case "":
          break;  
        case "":
          break;  
        default:
          this.setState({ currentPasswordError: '' });
          break;
      }
    
      switch (confirm_password_error) {
        case 'Password is not matching':
          this.setState({ confirmPasswordError: this.handleCondition(this.props.i18n.language === 'ar' , 'كلمة المرور غير متطابقة' , confirm_password_error )});
          break;
        case "":
          break;  
        case "":
          break;    
        default:
          this.setState({ confirmPasswordError: '' });
          break;
      }
    
      switch (new_password_error) {
        case 'Password is not matching':
          this.setState({ newPasswordError: this.handleCondition(this.props.i18n.language === 'ar', 'كلمة المرور غير متطابقة', new_password_error )});
          break;
        case "":
          break;  
        case "":
          break;    
        default:
          this.setState({ newPasswordError: '' });
          break;
      }
    }
  }
  handleNavigationMessage = (message:any) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      if (sessionData) {
        if(sessionData.Message=="MyProfile"){
          this.setState({tabValue:0});
        }
      }
    }  
   
  };
  handleDeactivateAccount = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.deactiveId) {
      if(!apiResponse.errors) { 
        toast.success(apiResponse.message)
        const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
        goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration");
        goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(goToContractForm);
      }
    }
  }
  
  handleChangeLanguage = () => {
    const newLanguage = this.state.currentLanguage === 'en' ? 'ar' : 'en';
    this.setState({
      currentLanguage: newLanguage,
      checkedSwitchArabic: newLanguage === 'ar',
      checkedSwitchEnglish: newLanguage === 'en'
    }, () => this.changeLanguage(newLanguage));
  }

  handleFeedback = ()=>{
    this.setState({feedback: !this.state.feedback})
  }

  closelogoutModal = () => {
    this.setState({popupForLogout: false})
  }

  navigateTo = (endpoint: string) => {
    const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
    goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), endpoint);
    goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToContractForm);
  }

  handleUserLogout = async() =>{
    this.closelogoutModal();
    await removeStorageData('token');
    this.navigateTo("EmailAccountLoginBlock")
  }

  // FAQ data request
  getFAQRequest = async () => {
    const token = await getStorageData("token");
    const hearder = {
      'Content-Type': configJSON.validationApiContentType,
      token,
    }
    const requestMessage = ApiRequest({
      hearder,
      endPoint: configJSON.faqEndpoint,
      method: "GET"
    })
    this.apiCallIdFaq = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get FAQ response
  getFAQResponse = (responseJson: any, errorMessage: any) => {  
    if (responseJson.errors) {
      // Handle error response
      const errorMessages = responseJson.errors.map((error: any) => {
        return Object.values(error).flat().join(', ');
      }).join('; ');
      toast.error(`Error: ${errorMessages}`);
    } else if (responseJson.data) {
      if (responseJson.data.attributes) {
        const { investor_description, frequently_asked_questions } = responseJson.data.attributes;
  
        const freqAskQuestion: IFAQ[] = frequently_asked_questions?.data?.map((item: any) => {
          return {
            id: item.id,
            question: item.attributes.question,
            description: item.attributes.description
          }
        }) || [];
        this.setState({
          investorDescription: investor_description,
          freqAskQuestion
        });
      }
    } else {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };
  
  setActiveAnswerIndex(index: any) {
    this.setState({
      activeAnswerIndex: this.state.activeAnswerIndex === index ? "" : index,
    });
  }

  navigateToDashBoardPage = ()=>{
    const goToDashboard = new Message(getName(MessageEnum.NavigationMessage));
    goToDashboard.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileBasicBlock");
    goToDashboard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToDashboard);
  }

  handleCloseContactUs = () => {
    this.setState({
      openContactUs: false
    })
  }

  handlePrivacyPolicyNavigate = () => {
    this.navigateTo("PrivacyPolicy")
  }
  
  handleOpenContactUs = () => {
    this.setState({
      openContactUs: true
    })
  }
  handleTermsConditionNavigate = () => {
    this.navigateTo("TermsConditions")
  }

  setCustomError = (msg: string) => {
    this.setState({customError: msg});
  }

  handleOpenPopup = () => {
    this.setState({ deactivatePopup: !this.state.deactivatePopup})
  }
  handleProfilePopup = () =>{
    this.setState({profilePopup: !this.state.profilePopup})
  }

  handleDeactivateAccountApi =()=>{
    const token = localStorage.getItem("token");
    const headers = {
      token: token,
    };
    let formData = new FormData();
    let deactive =  "false";

    formData.append('data[activated]', deactive);
    const requestData= new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deactiveId = requestData.messageId;
    requestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deactivateAccApiEndPoint
    );

    requestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    runEngine.sendMessage(requestData.id, requestData);
  }

  handleLanguageChangeRequest = async () => {
    const { currentLanguage } = this.state;
    const formData = new FormData();
    formData.append("language", currentLanguage);

    const token = await getStorageData("token");
    const header = {
      token
    }

    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.updateLanguageEndpoint,
      method: "PUT",
    });

    this.apiCallIdLanguageUpdate = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLanguageChangeResponse = (responseJson: any, errorResponse: any) => {
    const message = responseJson.meta.message;
    if(responseJson && responseJson.data && responseJson.data.attributes.language) {
      toast.success(message)
    } else {
      toast.error(message)
    }
  };

  changeLanguage = (lng: string) => {
    this.handleLanguageChangeRequest();

    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  navigateToHelp = () => {
    this.navigateTo("HelpCentre")
  }
  // Customizable Area End

}
