// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import { IDepostiStages, IPaginationData, TStatus } from "./MultipageFormsController.web";
import { approvedIcon, formFillInitIcon, formFillLaterIcon, formReviewInitIcon, formReviewLaterIcon, rejectedIcon } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import ApiRequest from "../../../components/src/ApiRequest.web";
import { formatDateToMonDayYear } from "../../../components/src/utils.web";
import React, { RefObject } from "react";

export const configJSON = require("./config");

interface IWithdrawList {
    date: string;
    transactionId: string;
    moneyWithdrawn: number;
    walletBalance: number;
    status: string;
}

const initialDepositStages: IDepostiStages[] = [
    {
      id: "1",
      IconInit: formFillInitIcon,
      IconPending: formFillLaterIcon,
      IconSuccess: approvedIcon,
      IconReject: rejectedIcon,
      title: 'Step 1',
      description: 'Form Filling',
      status: "fill form",
      successStatus: "submitted",
    },
    {
      id: "2",
      IconInit: formReviewInitIcon,
      IconPending: formReviewLaterIcon,
      IconSuccess: approvedIcon,
      IconReject: rejectedIcon,
      title: 'Step 2',
      description: 'Form Review',
      status: "not started",
      successStatus: "approved",
    },
  ]
export interface Props {
    navigation: any;
    id: string;
}
interface S {
    withdrawStages: IDepostiStages[];
    showButton: boolean;
    sortBy: string;
    order: string;
    perPage: string;
    paginationData: IPaginationData,
    withdrawList: IWithdrawList[];
    requestId: string;
    buttonPopup:boolean;
    navigationLink: string;
    activeTab: number;
    listingData:any;
 }
interface SS { }
// Customizable Area End

export default class WithdrawRequestControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdLastWithdrawFormStatus: string = "";
    apiCallIdWithdrawRequestList: string = "";
    apiCallIdWithRequestId: string = "";
    standingListingApi:string = "";
    popupRef: RefObject<HTMLDivElement>;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        this.state = {
            withdrawStages: [],
            showButton: true,
            sortBy: '',
            order: '',
            perPage: '9',
            buttonPopup:false,
            listingData:[],
            paginationData: {
                currentPage: 1,
                nextPage: 0,
                prevPage: 0,
                totalPages: 0,
                currentCount: 0,
                totalCount: 0
              },
              withdrawList: [],
              requestId: '',
              navigationLink: '',
              activeTab: 0
        };
        this.popupRef = React.createRef();
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        const apiRequestCallIds = {
          [this.apiCallIdLastWithdrawFormStatus]: this.getWithdrawFormStatusResponse,
          [this.apiCallIdWithdrawRequestList]: this.getWithdrawRequestListResponse,
          [this.apiCallIdWithRequestId]: this.generateWithdrawRequestIdResponse
    
          // Add more API call IDs and handlers as needed
        };
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apierror = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        
        if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
          apiRequestCallIds[apiRequestCallId](apiResponse, apierror);
        }
        if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
          apiRequestCallIds[apiRequestCallId](apiResponse, apierror);
        }
        if (apiRequestCallId === this.standingListingApi) {
          if(apiResponse){
           this.setState({listingData: apiResponse.data})
          }
         }
      }

    // Life-cycle method

    async componentDidMount() {
       this.getWithdrawFormStatusRequest();
       this.getStandingFormListingApi();


       this.getWithdrawRequestListRequest();
       document.addEventListener('mousedown', this.handleClickOutside);
    }

    async componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: MouseEvent) => {
      if (this.popupRef.current && !this.popupRef.current.contains(event.target as Node)) {
        this.setState({ buttonPopup: false });
      }
    };

    // Request method

    getWithdrawFormStatusRequest = async () => {
        let token = await getStorageData("token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };
        const requestMessage = ApiRequest({
            header: header,
            endPoint: configJSON.lastWithdrawFormStatusEndpoint,
            method: "GET",
        });

        this.apiCallIdLastWithdrawFormStatus = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getWithdrawRequestListRequest = async () => {
        const {sortBy, order, paginationData, perPage} = this.state;
        let token = await getStorageData("token");
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token
        };
        const params = new URLSearchParams();

        if (sortBy) params.append("sort_by", sortBy);
        if (order) params.append("order", order);
        if (paginationData.currentPage) params.append("page", paginationData?.currentPage.toString());
        if (perPage) params.append("per_page", perPage);

        const requestMessage = ApiRequest({
          header: header,
          endPoint: `${configJSON.listingWithdrawRequestEndpoint}?${params.toString()}`,
          method: "GET",
        });
    
        this.apiCallIdWithdrawRequestList = requestMessage.messageId;
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      generateWithdrawRequestIdRequest = async () => {
        let token = await getStorageData("token");
        const header = {
            token
        };
        const formData = new FormData();
        formData.append("data[request_type]", "withdraw_request");
        const requestMessage = ApiRequest({
            header: header,
            endPoint: configJSON.generateRequestIdEndpoint,
            payload: formData,
            method: "POST",
        });

        this.apiCallIdWithRequestId = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    // Response method

    getWithdrawFormStatusResponse = async (responseJson: any, errorResponse: any) => {
        const { data, message } = responseJson || {};
        const attributes = data?.[0]?.attributes;
        const newUser = message === "No Withdraw Request Found";
      
        if (attributes) {
          const statusMap: { [key: string]: TStatus } = {
            accepted: "approved",
            pending: "pending",
            rejected: "rejected",
          };
      
          const newStatus = statusMap[attributes.status] || "not started";
          const formattedValue = (!attributes.request_id || attributes.request_id === "null") ? await getStorageData("withdrawRequestId") : attributes.request_id
          this.setState({
            withdrawStages: initialDepositStages.map((stage, index) => {
              if (index === 0) {
                return { ...stage, status: stage.successStatus };
              } else if (index === 1) {
                return { ...stage, status: newStatus };
              }
              return stage;
            }),
            requestId: formattedValue
          }, () => {
              this.checkButtonVisibility();
          });
        }
      
        if (newUser) {
          this.setState({ withdrawStages: [], listingData: [] });
        }
      };

      getWithdrawRequestListResponse = (responseJson: any, errorResponse: any) => {
        if (responseJson.data) {
          const rows: IWithdrawList[] = responseJson.data.map((item: any) => {
            const attributes = item.attributes;
            const formattedValue = (!attributes.request_id || attributes.request_id === "null") ? "N/A" : attributes.request_id
            return {
              date: formatDateToMonDayYear(attributes.created_at),
              transactionId: formattedValue,
              moneyWithdrawn: `OMR ${attributes.amount.toFixed(2)}`,
              walletBalance: `OMR ${attributes.wallet_balance.toFixed(2)}`,
              status: attributes.status === 'accepted' ? 'completed' : attributes.status,
            };
          });
      
          const metaResponse = responseJson.meta;
      
          const pagination: IPaginationData = {
            currentPage: metaResponse?.current_page || 1,
            nextPage: metaResponse?.next_page || null,
            prevPage: metaResponse?.prev_page || null,
            totalPages: metaResponse?.total_pages || 1,
            currentCount: metaResponse?.current_count || rows.length,
            totalCount: metaResponse?.total_count || rows.length,
          };
      
          this.setState({ withdrawList: rows, paginationData: pagination });
        }
      };

      generateWithdrawRequestIdResponse = (responseJson: any, errorResponse: any) => {
        if(responseJson?.request_id) {
          this.setState({requestId: responseJson.request_id}, async () => await setStorageData("withdrawRequestId", responseJson.request_id))
        }
      };

    // Internal method

    isPrevStageCompleted = (index: number) => {
        if(index === 0) return true;
    
        const current_index = index - 1;
        const current_stage = this.state.withdrawStages[current_index]
    
        return current_stage.status === current_stage.successStatus;
      }
    
      stepColor = (status: TStatus) => {
        const colors: {[key: string]: string} = {
          pending: "#F59E0B",
          approved: '#10B981',
          completed: '#10B981',
          rejected: '#EF4444',
          submitted: '#10B981',
          'fill form': '#1B4FE4',
          'not started': '#F1F5F9'
        }
    
        return colors[status] || '#F1F5F9';
      }
    
      getIconForStages = (index: number) => {
        const current_stage = this.state.withdrawStages[index];
        const isPrevStageCompleted = this.isPrevStageCompleted(index);
        const pendingIcon = isPrevStageCompleted && current_stage.status === 'pending'
        const initialInactive = !isPrevStageCompleted && (current_stage.status === 'not started' || current_stage.status === 'fill form')
        if(current_stage.status === current_stage.successStatus) {
        return current_stage.IconSuccess
        } 
    
        if(pendingIcon) {
          return current_stage.IconPending;
        }
    
        if(current_stage.status === 'rejected') {
          return current_stage.IconReject
        }
        if(initialInactive) {
          return current_stage.IconInit
        }
        if(isPrevStageCompleted) {
          return current_stage.IconInit
        }
      }
    
      navigateTo = (endpoint: string) => {
        const goToContractForm = new Message(getName(MessageEnum.NavigationMessage));
        goToContractForm.addData(getName(MessageEnum.NavigationTargetMessage), endpoint);
        goToContractForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(goToContractForm);
      }

    checkButtonVisibility = () => {
        const { withdrawStages } = this.state;
    
        const anyRejected = withdrawStages.some(stage => stage.status === 'rejected');
        const allCompletedOrApproved = withdrawStages.every(stage =>
          stage.status === 'completed' || stage.status === 'approved' || stage.status === 'submitted'
        );
        const anyInProgress = withdrawStages.some(stage => 
          stage.status !== 'not started' && stage.status !== 'completed' && stage.status !== 'rejected'
        );
    
        if (anyRejected || allCompletedOrApproved || !anyInProgress) {
          this.setState({ showButton: true });
        } else {
          this.setState({ showButton: false });
        }
      };

      addNewWithdrawRequest = (navigationLink: string, index: number) => {
        this.setState({ 
          withdrawStages: initialDepositStages,
          showButton: false,
          navigationLink,
          activeTab: index
        }, async () => {
          this.checkButtonVisibility();
          this.generateWithdrawRequestIdRequest();
        });
      }

      handlePaginatioChange = (event: any, next: number) => {
        this.setState((prevState) => ({
          paginationData: {
            ...prevState.paginationData,
            currentPage: next
          },
          withdrawList: [],
        }), () => this.getWithdrawRequestListRequest())
      }

      handleButtonPopup = () => {
        this.setState((prevState) => ({
          buttonPopup: !prevState.buttonPopup,
        }));
      };

      handleNavigateToStandingForm = () => {
        this.navigateTo("StandingForm")
      }

      handleNavigateToWithdrawForm = () => {
        this.navigateTo("WithdrawForm")
      }

      handleCondition = (condition: boolean, truePart: string, falsePart: string) => {
        return condition ? truePart : falsePart;
      };

      getStandingFormListingApi = () => {
        const token = localStorage.getItem("token")
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.standingListingApi = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.standingFormListingEndpoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };

    // Customizable Area End
}
